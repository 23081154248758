import React, {useState, useEffect} from 'react'
import styled from 'styled-components/macro'
import {useLocation} from 'react-router-dom'

import {CellContext, ColumnDef} from '@tanstack/react-table'
import ResizableTable from 'common/tables/ResizableTable/ResizableTable'

// import { format } from "date-fns"
import DeviceSwapModal from './DeviceSwapModal'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {deviceSwapTypes} from './managementHelpers'
import useDevices from 'management/hooks/useDevices'
import useHotSwapDevices from 'management/hooks/useHotSwapDevices'
import {CustomerType} from 'management/hooks/useCustomers'
import {EditDeviceType} from './TemedaAdmin/Devices/hooks/usePutDevice'

type Props = {
  selected: CustomerType | null
  height?: string
}

const CenterCell = styled.div`
  display: flex;
  justify-content: center;
  text-align: center;
  width: 100%;
`
const NoCustomerSelectedMessage = styled.div`
  display: flex;
  flex-direction: row;
  z-index: 1;
  border: 1px solid #dedede;
  border-radius: 5px;
  margin: 0;
  height: 300px;
  justify-content: center;
  align-items: center;
  font-size: 1.7rem;
  padding: 10px;
`

const LeftCell = styled.div`
  display: flex;
  justify-content: flex-start;
  text-align: flex-start;
  width: 100%;
`

const DeviceNotes = styled.div`
  width: 275px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

const DeviceSwapTable = (props: Props) => {
  const {selected} = props
  const location = useLocation()
  const {pathname} = location
  const [isSwapDeviceModalShown, setIsSwapDeviceModalShown] = useState(false)
  const [selectedSrcDevice, setSelectedSrcDevice] =
    useState<EditDeviceType | null>(null)
  const {data: devices, status: devicesStatus} = useDevices(
    selected?.idcustomer,
  )
  const {data: hotSwapDevices, status} = useHotSwapDevices()

  const [devicesForSwap, setDevicesForSwap] = useState<EditDeviceType[]>([])
  useEffect(() => {
    const idCustomer = selected?.idcustomer
    if (hotSwapDevices) {
      const filteredDevices = hotSwapDevices.filter(
        (device: EditDeviceType) =>
          device.idCustomer_Final === idCustomer || device.idDealer_Swappable,
      )
      setDevicesForSwap(filteredDevices)
    }
  }, [hotSwapDevices, selected])

  const showDeviceSwapModal = (srcDevice: EditDeviceType) => {
    setSelectedSrcDevice(srcDevice)
    setIsSwapDeviceModalShown(true)
  }

  const actionCell = (instance: CellContext<EditDeviceType, unknown>) => {
    const device = instance.row.original
    return (
      <CenterCell>
        <FontAwesomeIcon
          icon="exchange-alt"
          style={{cursor: 'pointer'}}
          onClick={() => showDeviceSwapModal(device)}
        />
      </CenterCell>
    )
  }

  const DeviceNotesCell = (instance: CellContext<EditDeviceType, unknown>) => {
    return <DeviceNotes>{String(instance.getValue() || '')}</DeviceNotes>
  }

  const deviceTypeCell = (instance: CellContext<EditDeviceType, unknown>) => {
    const idDeviceType = instance.row.original.idDevice_Type
    return `${String(instance.getValue())} (${idDeviceType})`
  }

  const swapTypeCell = (instance: CellContext<EditDeviceType, unknown>) => {
    const type = instance.row.original.DeviceSwapType
    const label = type ? deviceSwapTypes[type] : null
    return <LeftCell>{label}</LeftCell>
  }

  const columns: ColumnDef<EditDeviceType>[] = [
    {
      header: 'Serial #',
      accessorKey: 'Serial_Number_Displayed',
    },
    {
      header: 'idDevice',
      accessorKey: 'idDevice',
    },
    {
      header: 'Device Type',
      accessorKey: 'Device_Type',
      cell: deviceTypeCell,
    },
    {
      header: 'Device Notes',
      accessorKey: 'Device_Notes',
      cell: DeviceNotesCell,
    },
    {
      header: 'IMEI',
      accessorKey: 'IMEI',
    },
    {
      header: 'SIMNBR',
      accessorKey: 'SIMNBR',
    },
    {
      header: 'Device Model',
      accessorKey: 'DeviceModel',
    },
    {
      header: 'Order #',
      accessorKey: 'Order_Nbr',
    },
    {
      header: 'Type',
      accessorKey: 'DeviceSwapType',
      cell: swapTypeCell,
    },
    {
      header: 'For Customer',
      accessorKey: 'idCustomer_Final',
    },
    {
      header: 'Actions',
      cell: actionCell,
      accessorKey: 'idDevice',
      id: 'actions',
    },
  ]

  const clearModal = () => {
    setSelectedSrcDevice(null)
    setIsSwapDeviceModalShown(false)
  }
  const hideModal = () => setIsSwapDeviceModalShown(false)

  if (pathname.startsWith('/customers') && !selected)
    return (
      <NoCustomerSelectedMessage>
        Select a customer above to see available devices.
      </NoCustomerSelectedMessage>
    )

  return (
    <>
      {/* {devicesStatus === 'success' ? (
        <Table
          data={devices}
          columns={columns}
          initSort={[{id: 'Created_On', desc: false}]}
          filterable={true}
          tableTitle={selected ? `${selected?.Customer_Name} Devices` : ''}
          hiddenNewButton={true}
          hiddenExportButton={true}
          height="190px"
        ></Table>
      ) : (
        devicesStatus === 'loading' &&
        selected?.idcustomer && <Spinner></Spinner>
      )} */}
      <ResizableTable<EditDeviceType, number>
        data={devices || []}
        columns={columns}
        title={'Device List'}
        initSort={[{id: 'Created_On', desc: false}]}
        status={status && devicesStatus ? 'success' : 'loading'}
        height={'30vh'}
        oneRowHeader={true}
      />
      {isSwapDeviceModalShown &&
        selected &&
        selected &&
        selected.idcustomer && (
          <DeviceSwapModal
            idDevice={selectedSrcDevice?.idDevice}
            hideModal={hideModal}
            clearModal={clearModal}
            srcDevice={selectedSrcDevice}
            hotSwapDevices={devicesForSwap}
            selectedIdCustomer={selected?.idcustomer}
          />
        )}
    </>
  )
}

export default DeviceSwapTable
